.footer {
  display: flex;
  justify-content: center;
  color: #666;
  margin: 50px 0px;

  img {
    width: 24px;
    height: 24px;
  }

  .container {
    width: 100%;
    max-width: 1400px;
    padding: 0 20px;

    .top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px;
      margin-bottom: 50px;

      .item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;

        h2 {
          font-size: 16px;
          color: #555;
        }

        span {
          font-weight: 300;
        }
      }
    }

    hr {
      margin: 50px 0px;
      height: 0;
      border: 1px solid #ebe9e9;
    }

    .bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px;
      align-items: center;
      font-size: 13px;

      .left {
        display: flex;
        align-items: center;
        gap: 20px;

        span {
          white-space: nowrap;
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 30px;

        .social {
          display: flex;
          gap: 20px;
        }

        .link {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}
