.register {
  display: flex;
  align-items: center;
  justify-content: center;

  .error {
    color: #d9534f;
  }
  .password-strength meter {
    width: 100%;
    height: 10px;
    border-radius: 5px;
  }
  
  .password-strength .strength-0 {
    background-color: #d9534f; /* Weak */
  }
  
  .password-strength .strength-1 {
    background-color: #f0ad4e; /* Fair */
  }
  
  .password-strength .strength-2 {
    background-color: #ffc107; /* Good */
  }
  
  .password-strength .strength-3 {
    background-color: #5cb85c; /* Strong */
  }
  
  .password-strength .strength-4 {
    background-color: #5cb85c; /* Very Strong */
  }
  

  form {
    width: 100%;
    max-width: 960px;
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;

    h1 {
      color: gray;
      margin-bottom: 20px;
      flex-basis: 100%;
      text-align: center;
    }

    .left,
    .right {
      flex-basis: 100%;
      max-width: 400px;
    }

    label {
      color: gray;
      font-size: 18px;
      display: block;
      margin-bottom: 10px;
    }

    input,
    textarea {
      padding: 20px;
      border: 1px solid rgb(216, 214, 214);
      width: 100%;
      margin-bottom: 15px;
    }

    button {
      border: none;
      padding: 20px;
      color: white;
      font-weight: 500;
      font-size: 18px;
      background-color: #1dbf73;
      cursor: pointer;
      width: 100%;
    }

    .toggle {
      display: flex;
      align-items: center;
      gap: 10px;

      .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 24px;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }

      input:checked + .slider {
        background-color: #2196f3;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }
  }
}
