.featured {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #013914;
  color: white;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 500px) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 20px;
      max-width: 1200px;
      margin: 0 auto;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 80%;
      padding-left: 10%;

      @media only screen and (min-width: 500px) {
        width: 50%;
        padding-left: 0;
      }

      h1 {
        font-size: 50px;

        span {
          font-style: italic;
          font-weight: 300;
        }
      }

      .search {
        background-color: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        .searchInput {
          display: flex;
          align-items: center;
          color: #000;
          gap: 10px;

          img {
            width: 20px;
            height: 20px;
            margin: 10px;
          }

          input {
            border: none;
            outline: none;

            &::placeholder {
              color: gray;
            }
          }
        }

        button {
          width: 120px;
          height: 50px;
          border: none;
          background-color: #1dbf73;
          color: white;
          align-self: flex-end;
          cursor: pointer;
        }
      }

      .popular {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          width: max-content;
        }

        button {
          width: max-content;
          color: white;
          border: 1px solid white;
          padding: 5px 10px;
          border-radius: 20px;
          background-color: transparent;
          font-size: 14px;
        }
      }
    }

    .right {
      height: 100%;
      margin-top: 30px;

      @media only screen and (min-width: 500px) {
        margin-top: 0;
        width: 50%;
        height: auto;
      }

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
