.question-icon {
  margin: 3%;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('https://res.cloudinary.com/dk2a01h3i/image/upload/v1683152629/question3_fkhzxl.png');
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.2s ease-in-out;
  }

  &::after {
    content: "Send an event using google calendar. This event will also be sent to the person who you are meeting with.";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    width: 300px;
  }

  &:hover::after {
    display: block;
  }
}
.question-icon-2 {
  margin: 3%;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('https://res.cloudinary.com/dk2a01h3i/image/upload/v1683152629/question3_fkhzxl.png');
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.2s ease-in-out;
  }

  &::after {
    content: "Sign into your google account to send an calendar event";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    width: 300px;
  }

  &:hover::after {
    display: block;
  }
}
.question-icon3 {
  margin: 3%;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('https://res.cloudinary.com/dk2a01h3i/image/upload/v1683152629/question3_fkhzxl.png');
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.2s ease-in-out;
  }

  &::after {
    content: "Once you have completed check out you will be brought to a chat and can set up your meeting there.  Also you can send your google calendar invite to google meeting before hand - we suggest this for safe";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    width: 250px;
  }

  &:hover::after {
    display: block;
  }
}

.cal-event {
  display: flex;
  flex-direction: row wrap;
  align-items: center;
  justify-content: left;
  
}
