.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .message {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #444;

    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .loader {
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-top: 3px solid #ffffff;
    border-right: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff;
    border-left: 3px solid #000;
    animation: spin 1s linear infinite;

    @media screen and (max-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
